import { styled } from '@mui/material';
import Colours from '../../../../Theme/Colours';

interface ContactRowDivProps {
  contactRowClass?: React.CSSProperties,
  isActive?: boolean;
};

export const Styles = {
  row: {
    color: Colours.Backgrounds.white,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: 48,
    padding: '3px 0px',
    '& > *': {
      // margin: 'auto',
      lineHeight: '36px',
    },
  },
  avatar: {
    width: '24px',
    height: '24px',
    marginRight: '6px',
    marginLeft: '6px',
  },
  selectedAvatar: {
    border: '2px solid #0BA360',
  },
  status: {
    '&.Available': {
      color: '#0BA360',
    },
    '&.AvailableMobile': {
      color: '#0BA360',
    },
    '&.Busy': {
      color: '#e392f7',
    },
    '&.Away': {
      color: '#F69D1B',
    },
  },
};

export const ContactRowDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'contactRowClass' && prop !== 'isActive',
})<ContactRowDivProps>(({ contactRowClass, isActive }) => ({
  ...(contactRowClass && contactRowClass),
  ...Styles.row,
  ...(!isActive && {opacity: '50%'})
}));
